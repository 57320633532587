export enum UniqueSellingPropositionPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export interface UniqueSellingProposition {
  id: string;
  text: string;
  position: UniqueSellingPropositionPosition;
}

export interface UniqueSellingPropositionParam {
  id?: string;
  text: string;
  position: UniqueSellingPropositionPosition;
}
