

















































































import Vue, { VueConstructor } from 'vue';
import { ApolloError } from 'apollo-client';
import Spinner from '@/components/Spinner.vue';
import { ELEMENTS_USPS_QUERY } from '../queries/query';
import { Table, TableColumn } from 'element-ui';
import {
  UniqueSellingProposition,
  UniqueSellingPropositionParam,
  UniqueSellingPropositionPosition,
} from '../model/usp';
import _ from 'lodash';
import { USPService } from '../service/USPservice';
import { apolloClient } from '@/apolloClient';

type TextPosition = '상단문구' | '하단 문구';

type USP = UniqueSellingProposition & {
  isChanging: boolean;
  textPosition: TextPosition;
};

const uspService = new USPService(apolloClient);

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  name: 'USPList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
  },
  data() {
    return {
      isAnyChanged: false,
      usps: [] as USP[],
      showErrorMessageTime: 0,
      errorMessage: '',
      showSuccessMessageTime: 0,
      successMessage: '',
      loading: 0,
    };
  },
  computed: {
    isAnyChangedType(): string {
      return !this.isAnyChanged ? 'secondary' : 'primary';
    },
  },

  methods: {
    async saveUSPs(): Promise<void> {
      this.$modal.show(
        {
          title: '변경 하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        async () => {
          try {
            // find id set
            const uspIds = new Set(this.usps.map(usp => usp.id));
            const inputUSPs: UniqueSellingPropositionParam[] = [];
            for (const uspId of uspIds) {
              const filterdUsps = this.usps.filter(usp => usp.id === uspId);
              let text = '';
              filterdUsps.forEach(usp => {
                if (_.toArray(usp.text).length === 0) {
                  throw new Error('문구는 필수 값 입니다.');
                }
                // emoji length
                // https://stackoverflow.com/questions/40362669/count-character-length-of-emoji/46085147#46085147
                if (_.toArray(usp.text).length > 30) {
                  throw new Error('최대길이 30 자를 넘었습니다.');
                }
                if (usp.text.includes('\n')) {
                  throw new Error('각 영역에 한줄만 허용 됩니다.');
                }
                //
                if (usp.textPosition === '상단문구') {
                  text = usp.text + text;
                } else {
                  text += '\n' + usp.text;
                }
              });
              inputUSPs.push({
                id: undefined,
                text: text,
                position: filterdUsps[0].position,
              });
            }
            await uspService.saveUSPs(inputUSPs);
            this.$apollo.queries.uspsQuery.refetch();
            this.showSuccessMessage('적용 되었습니다.');
            this.isAnyChanged = false;
          } catch (err) {
            console.error(err);
            this.showErrorMessage(err.message);
          }
        }
      );
    },
    showErrorMessage(message: string): void {
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },
    countDownChanged(showErrorMessageTime: number) {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    showSuccessMessage(message: string): void {
      this.showSuccessMessageTime = 5;
      this.successMessage = message;
    },
    countDownSuccessChanged(showSuccessMessageTime: number) {
      this.showSuccessMessageTime = showSuccessMessageTime;
    },
  },
  apollo: {
    uspsQuery: {
      query: ELEMENTS_USPS_QUERY,
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update(data: {
        elements: { uniqueSellingPropositions: UniqueSellingProposition[] };
      }): void {
        const resultUSPs = data.elements.uniqueSellingPropositions;
        this.usps = [];
        // usp 는 오직 1개이며, usp 의 top 만이 진짜 USP 임.
        const uspFinded = resultUSPs.find(
          usp => usp.position === UniqueSellingPropositionPosition.TOP
        );
        const textPosiotions: TextPosition[] = ['상단문구', '하단 문구'];
        if (!uspFinded) {
          for (const textPosition of textPosiotions) {
            const defaultValue: USP = {
              id: '0',
              text: '',
              textPosition: textPosition,
              position: UniqueSellingPropositionPosition.TOP,
              isChanging: false,
            };
            this.usps.push(defaultValue);
          }
        } else {
          // uspFinded
          const splitedText = uspFinded.text.split('\n');
          for (const [idx, textPosition] of textPosiotions.entries()) {
            const defaultValue: USP = {
              id: uspFinded.id,
              text: splitedText[idx] || '',
              textPosition: textPosition,
              position: UniqueSellingPropositionPosition.TOP,
              isChanging: false,
            };
            this.usps.push(defaultValue);
          }
        }
      },
    },
  },
});
