import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { SAVE_USPS_MUTATION } from '../queries/mutation';
import {
  UniqueSellingPropositionParam,
  UniqueSellingPropositionPosition,
} from '../model/usp';

export class USPService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }
  async saveUSPs(params: UniqueSellingPropositionParam[]): Promise<void> {
    this.validate(params);
    await this.apollo.mutate({
      mutation: SAVE_USPS_MUTATION,
      variables: {
        params: params,
      },
    });
  }
  validate(params: UniqueSellingPropositionParam[]): void {
    const paramPositions = params.map(({ position }) => {
      return position;
    });
    const paramPositionsSet = new Set(paramPositions);
    if (paramPositionsSet.size != paramPositions.length) {
      throw new Error('중복되는 위치의 값이 존재합니다.');
    }
    const positions = Object.keys(UniqueSellingPropositionPosition);
    paramPositionsSet.forEach(position => {
      if (!positions.includes(position)) {
        throw new Error('존재하지 않는 포지션이 존재합니다.');
      }
    });
  }
}
