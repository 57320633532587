import gql from 'graphql-tag';

export const SAVE_USPS_MUTATION = gql`
  mutation ($params: [UniqueSellingPropositionParam!]!) {
    saveUniqueSellingPropositions(params: $params) {
      id
      text
      position
    }
  }
`;
